<template>
  <!--begin::ChatsShipments table-->
  <div class="gray-box">
    <!--begin::Row Filters-->
    <div class="white-box mb-9">
      <div class="row pt-3 align-items-top">
        <div class="col-md-8 d-flex d-row align-items-top">
          <h5 class="mr-3 mt-3 font-weight-bolder" style="white-space: pre">
            {{ $t("CHATS.SEARCH") }}
          </h5>
          <v-text-field
            class="form-control search-text"
            v-model.lazy="SearchText"
            :placeholder="$t('GENERIC.SEARCH') + '...'"
            @keyup="onSearchTextKeyup"
          >
            <template v-slot:prepend-inner>
              <inline-svg src="/media/icons/search-alt-1-primary.svg" />
            </template>
          </v-text-field>
        </div>
      </div>
    </div>
    <!--end::Row Filters-->

    <!--begin::Row Searching-->
    <div v-if="ItemsSearching" class="search-info">
      <inline-svg class="mx-3" src="/media/icons/help-alt-5-success.svg" />
      {{ $t("GENERIC.SEARCHING") }}...
    </div>
    <!--end::Row Searching-->

    <!--begin::Table Results-->
    <v-data-table
      id="ChatsTable"
      v-if="ItemsList.length > 0"
      class="white-table shadow-box"
      :headers="TableHeaders"
      :items="ItemsList"
      :items-per-page="ItemsPerPage"
      :options.sync="options"
      :server-items-length="ItemsTotal"
      @update:sort-by="updateSortBy"
      @update:sort-desc="updateSortDesc"
      hide-default-footer
    >
      <template v-slot:item="{ item }">
        <tr>
          <td>
            <div>
              <strong v-if="item.ShipmentInfo.AlertCountChat === 0">
                {{ item.ShipmentInfo.ShipmentID }}
              </strong>
              <v-badge v-else left inline color="bg-danger text-white">
                <template v-slot:badge>
                  {{ item.ShipmentInfo.AlertCountChat }}
                </template>
                <strong class="ml-1">{{ item.ShipmentInfo.ShipmentID }}</strong>
              </v-badge>
            </div>
            <div>
              {{ item.ShipmentInfo.VehicleModelFirst }}
              <span v-if="item.ShipmentInfo.VehiclesCount > 1">
                {{
                  $tc(
                    "CHATS.AND_VEHICLES",
                    item.ShipmentInfo.VehiclesCount - 1,
                    {
                      count: item.ShipmentInfo.VehiclesCount - 1
                    }
                  )
                }}
              </span>
            </div>
          </td>

          <td>
            {{ item.LatestMessage.Content }}
          </td>

          <td>
            {{
              item.LatestMessage.FromName
                ? item.LatestMessage.FromName
                : $t("CHATS.FROM_MYSELF")
            }}
          </td>

          <td>
            {{ formatCreatedAtDate(item.LatestMessage.CreatedAt) }}
            <br />
            {{ formatCreatedAtTime(item.LatestMessage.CreatedAt) }}
          </td>

          <td class="pt-3">
            <span
              class="action-icon"
              @click="
                goToProfile(
                  item.ShipmentInfo.ShipmentID,
                  item.ShipmentInfo.ShipmentLegVehicleIDFirst
                )
              "
            >
              <inline-svg src="/media/icons/view-primary.svg" />
            </span>
          </td>
        </tr>
      </template>
    </v-data-table>
    <!--end::Table Results-->

    <!--begin::Row Pagination-->
    <div v-if="!ItemsSearching && ItemsList.length > 0" class="p-0 row">
      <div
        class="p-4 d-flex justify-content-between align-items-center flex-wrap w-100"
      >
        <div>
          {{
            $t("GENERIC.PAGINATION_SHOW", {
              from: ItemsFrom,
              to: ItemsTo,
              total: ItemsTotal
            })
          }}
        </div>

        <v-pagination
          v-model="ItemsCurrentPage"
          :length="ItemsPages"
          @input="searchChats(true)"
          :previous-aria-label="$t('GENERIC.PREVIOUS')"
          :next-aria-label="$t('GENERIC.NEXT')"
        />

        <div class="d-flex align-items-baseline py-3">
          <v-select
            class="form-control form-control-sm mr-0 border-0 bg-light"
            style="max-width: 5em;"
            v-model="ItemsPerPage"
            single-line
            :items="ItemsPerPageOptions"
            @change="updateItemsPerPage"
          />
          <span>{{ $t("GENERIC.PAGINATION_MENU") }}</span>
        </div>
      </div>
    </div>
    <!--end::Row Pagination-->

    <!--begin::Row Zero-->
    <div v-if="!ItemsSearching && ItemsList.length < 1" class="search-info">
      <inline-svg class="mx-3" src="/media/icons/help-alt-5-success.svg" />
      {{ $t("GENERIC.NO_RESULTS") }}
    </div>
    <!--end::Row Zero-->
  </div>
  <!--end::ChatsShipments table-->
</template>

<style scoped>
#ChatsTable thead th {
  white-space: nowrap !important;
}
#ChatsTable tbody td {
  vertical-align: top !important;
  padding-top: 3px;
}
</style>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import i18nService from "@/core/services/i18n.service.js";

import ShipmentService from "@/core/services/api/v2/shipment.service";

export default {
  name: "ChatsShipmentsTable",

  props: {
    atOffer: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data() {
    return {
      TableHeaders: [
        { text: this.$t("CHATS.SHIPMENT_ID"), value: "ShipmentID" },
        { text: this.$t("CHATS.LATEST_MSG"), value: "Content" },
        { text: this.$t("CHATS.FROM"), value: "FromName" },
        { text: this.$t("CHATS.DATE"), value: "CreatedAt" },
        { text: this.$t("GENERIC.ACTIONS"), width: 120, sortable: false }
      ],
      options: {},
      ProvinceOptions: [],
      StatusOptions: [],
      DateFormatEN: "YYYY-MM-DD",
      DateFormatES: "DD/MM/YYYY",
      DateFormatLang: "DD/MM/YYYY",
      SortBy: "CreatedAt",
      SortDesc: true,
      ItemsList: [],
      ItemsSearching: true,
      DebounceSearchText: null,
      SearchText: "",
      ItemsFrom: 0,
      ItemsTo: 0,
      ItemsTotal: 0,
      ItemsPerPage: 5,
      ItemsPages: 1,
      ItemsCurrentPage: 1,
      ItemsPerPageOptions: [
        { text: 5, value: 5 },
        { text: 10, value: 10 },
        { text: 25, value: 25 },
        { text: 50, value: 50 }
      ]
    };
  },

  computed: {
    ...mapGetters(["currentUserRoleID", "rolesList"])
  },

  created() {
    // Format for dates
    let userLang = i18nService.getActiveLanguage();
    if (userLang === "en") {
      this.DateFormatLang = this.DateFormatEN;
    } else {
      this.DateFormatLang = this.DateFormatES;
    }
  },

  mounted() {
    // Do first search
    this.searchChats(false);
  },

  methods: {
    searchChats(keepCurrentPage) {
      this.ItemsSearching = true;
      this.ItemsList = [];

      // Compute pagination params
      if (!keepCurrentPage) {
        this.ItemsCurrentPage = 1;
      }

      const limit = this.ItemsPerPage;
      const offset =
        this.ItemsCurrentPage === 0 ? 0 : limit * (this.ItemsCurrentPage - 1);

      ShipmentService.searchAllChats(
        this.atOffer,
        this.SearchText,
        this.SortBy,
        this.SortDesc,
        limit,
        offset
      ).then(rawResponse => {
        if (rawResponse === 404) {
          this.ItemsList = [];
          this.ItemsPages = 1;
        } else {
          this.ItemsList = rawResponse.response.Chats;

          this.ItemsTotal = rawResponse.total;
          this.ItemsPages = Math.ceil(this.ItemsTotal / this.ItemsPerPage);
          this.ItemsFrom = (this.ItemsCurrentPage - 1) * this.ItemsPerPage + 1;
          this.ItemsTo = this.ItemsFrom + this.ItemsList.length - 1;
        }

        this.ItemsSearching = false;
      });
    },

    updateSortBy(event) {
      if (event.length > 0) {
        this.SortBy = event[0];
        this.SortDesc = false;
        this.ItemsCurrentPage = 1;
        this.searchChats(false);
      }
    },
    updateSortDesc(event) {
      this.SortDesc = event.length > 0 ? event[0] : this.SortDesc;
      this.ItemsCurrentPage = 1;
      this.searchChats(false);
    },
    updateItemsPerPage(newValue) {
      this.ItemsPerPage = newValue;
      this.ItemsCurrentPage = 1;
      this.searchChats(false);
    },

    changeItemsCurrentPage() {
      this.searchChats(true);
    },

    onSearchTextKeyup() {
      clearTimeout(this.DebounceSearchText);
      this.DebounceSearchText = setTimeout(() => {
        this.searchChats(false);
      }, 600);
    },

    formatCreatedAtDate(createdAt) {
      if (createdAt && createdAt.length > 0) {
        return moment(createdAt).format(this.DateFormatLang);
      } else {
        return "-";
      }
    },
    formatCreatedAtTime(createdAt) {
      if (createdAt && createdAt.length > 0) {
        return moment(createdAt).format("HH:mm:ss");
      } else {
        return "";
      }
    },

    goToProfile(shipmentID, shipmentLegVehicleID) {
      let profileUrl = "/manager/";
      if (this.atOffer) {
        profileUrl +=
          "offers/standard/edit/" +
          shipmentID +
          "?ActiveTab=messages&FromPage=chats";
      } else {
        profileUrl +=
          "shipments/edit/" +
          shipmentLegVehicleID +
          "?ActiveTab=messages&FromPage=chats";
      }

      this.$router.push(profileUrl);
    }
  }
};
</script>
