<template>
  <!-- begin::Chats tabs -->
  <div v-if="isAuthenticated">
    <div class="manager-title">
      <inline-svg
        class="manager-title-icon"
        src="/media/icons/chat-primary.svg"
      />
      <div>
        {{ $t("CHATS.TITLE") }}
        <hr class="manager-title-sub" />
      </div>
    </div>

    <div v-if="currentUserRoleID === this.rolesList.ID_OBSERVER">
      <ChatsShipmentsTable :at-offer="false" />
    </div>

    <div v-else>
      <v-tabs v-model="ActiveTab" align-with-title class="mb-3 mx-0">
        <v-tabs-slider color="#61bb0c"></v-tabs-slider>

        <v-tab grow href="#shipments">
          <span v-if="AlertCountTracking === 0">
            {{ $t("MENU.SHIPMENTS") }}
          </span>
          <v-badge v-else inline color="bg-danger text-white">
            <template v-slot:badge>
              {{ AlertCountTracking }}
            </template>
            <span class="mr-3">{{ $t("MENU.SHIPMENTS") }}</span>
          </v-badge>
        </v-tab>

        <v-tab grow href="#offers">
          <span v-if="AlertCountOffer === 0">
            {{ $t("MENU.OFFERS") }}
          </span>
          <v-badge v-else inline color="bg-danger text-white">
            <template v-slot:badge>
              {{ AlertCountOffer }}
            </template>
            <span class="mr-3">{{ $t("MENU.OFFERS") }}</span>
          </v-badge>
        </v-tab>
      </v-tabs>

      <v-tabs-items class="mx-0" :value="ActiveTab">
        <v-tab-item value="shipments">
          <ChatsShipmentsTable :at-offer="false" />
        </v-tab-item>

        <v-tab-item value="offers">
          <ChatsShipmentsTable :at-offer="true" />
        </v-tab-item>
      </v-tabs-items>
    </div>
  </div>
  <!-- end::Chats tabs -->
</template>

<script>
import { mapGetters } from "vuex";
import ChatsShipmentsTable from "@/view/pages/manager/chats/ChatsShipmentsTable.vue";
import ShipmentService from "@/core/services/api/v2/shipment.service";

export default {
  name: "ChatsTabs",
  components: {
    ChatsShipmentsTable
  },

  data() {
    return {
      AlertCountOffer: 0,
      AlertCountTracking: 0
    };
  },

  computed: {
    ...mapGetters(["isAuthenticated", "currentUserRoleID", "rolesList"]),

    ActiveTab: {
      set(ActiveTab) {
        this.$router.replace({ query: { ...this.$route.query, ActiveTab } });
      },
      get() {
        return this.$route.query.ActiveTab;
      }
    }
  },

  mounted() {
    this.updateAlerts();
  },

  methods: {
    updateAlerts() {
      ShipmentService.getAllChatsAlertCount().then(response => {
        this.AlertCountOffer = response.Offer;
        this.AlertCountTracking = response.Tracking;
      });
    }
  }
};
</script>
